body {
    font-family: "Josefin Sans", sans-serif !important;
}
html {
    scroll-behavior: smooth;
}
* {
    box-sizing: border-box;
}

/* text-colors */
.text-gray1 {
    color: #5b5b5b;
}
.text-gray2 {
    color: #455a64;
}
.text-gray3 {
    color: #ebebeb;
}
.purple-text {
    color: #bf5dab;
}
.text-dark {
    color: #151d41;
}
.text-dark2 {
    color: #070e17;
}
.text-gray4 {
    color: #808291;
}
.text-orange {
    color: #f1c65a;
}
.text-green {
    color: #0dd55d;
}
.text-Dorange {
    color: #ff7b33;
}
.text-blue {
    color: #006aff;
}

/* background colors */
.purple-bg {
    background-color: #bf5dab;
}

.purple-bg2 {
    background-color: rgba(180, 86, 161, 0.65);
}

.purple-light {
    background-color: rgba(191, 93, 200, 0.55);
}
.black-bg {
    background-color: black;
}
.bg-graylight {
    background-color: rgba(191, 93, 171, 0.24);
}
.bg-transparent {
    background-color: transparent;
}
.bg-red {
    background-color: #ff6e91;
}
.bg-arrow {
    background-color: #f4eeff;
}
.bg-skyb {
    background-color: #e4ebfd;
}
.bg-skyb2 {
    background-color: #eef2fa;
}
.bg-gray {
    background-color: #606a71;
}
.bg-white {
    background-color: white;
}
.bg-orange {
    background-color: #f1c65a;
}
.bg-green {
    background-color: #0dd55d;
}
.bg-green-light {
    background-color: #69eb6e;
}
.bg-Dorange {
    background-color: #ff7b33;
}
.bg-Dorange2 {
    background-color: rgba(255, 123, 51, 0.74);
}

/* placeholder color */

.p-g::placeholder {
    color: #ebebeb;
}
.p-b::placeholder {
    color: #7c8db5;
}
.p-g:focus {
    outline: none;
}

.p-b:focus {
    outline: none;
}

/* font-zize */
.fs70 {
    font-size: 70px;
}
.fs20 {
    font-size: 20px;
}
.fs22 {
    font-size: 22px;
}
.fs40 {
    font-size: 40px;
}
.fs-13 {
    font-size: 13px;
}
.fs24 {
    font-size: 24px;
}
.fs25 {
    font-size: 25px;
}
.fs23 {
    font-size: 23px;
}
.fs49 {
    font-size: 49px;
}
.fs17 {
    font-size: 17px;
}
.fs14 {
    font-size: 14px;
}
.fs60 {
    font-size: 60px;
}
.fs15 {
    font-size: 15px;
}
.fs27 {
    font-size: 27px;
}
.fs50 {
    font-size: 50px;
}
.fs27 {
    font-size: 27px;
}
.fs35 {
    font-size: 35px;
}
.fs12 {
    font-size: 12px;
}

/* line height */
.leading1 {
    line-height: 1;
}
.leading2 {
    line-height: 2;
}
.l125 {
    line-height: 1.25;
}
.l13 {
    line-height: 1.3;
}
.l72 {
    line-height: 72px;
}
.leading167 {
    line-height: 1.67;
}
.leading163 {
    line-height: 1.63;
}
.l30 {
    line-height: 30px;
}

.l45 {
    line-height: 45px;
}

/* letter spacing */
.ls05 {
    letter-spacing: 0.04px;
}
.ls72 {
    letter-spacing: 0.72px;
}

/*font weights */
.f-w-b {
    font-weight: 700;
}
.f-w-s {
    font-weight: 600;
}

/* font-alignment */
.t-s {
    text-align: center;
}

/* padding top */
.pt-49 {
    padding-top: 49px;
}

.pt-90 {
    padding-top: 90px;
}
.pt-215 {
    padding-top: 215px;
}
.pt-20 {
    padding-top: 20px;
}
.pt-30 {
    padding-top: 30px;
}
.pt-8 {
    padding-top: 8px;
}
.pt-3 {
    padding-top: 3px !important;
}

/* padding left */

.pl-32 {
    padding-left: 32px;
}
.pl-46 {
    padding-left: 46px;
}
.pl-74 {
    padding-left: 74px;
}
.pl-20 {
    padding-left: 20px;
}
.pl-22 {
    padding-left: 22px;
}
.pl-24 {
    padding-left: 24px;
}
.pl-3 {
    padding-left: 3px;
}
.pl-5 {
    padding-left: 5px;
}
.pl-6 {
    padding-left: 6px;
}

/* padding bottom */

.pb-70 {
    padding-bottom: 70px;
}
.pb-16 {
    padding-bottom: 16px;
}
.pb-10 {
    padding-bottom: 10px;
}
.pb-41 {
    padding-bottom: 41px;
}

/* padding right */

.pr-24 {
    padding-right: 24px;
}
.pr-22 {
    padding-right: 22px;
}
.pr-16 {
    padding-right: 16px;
}
.pr-3 {
    padding-right: 3px;
}

/* padding x */
.px-40 {
    padding-left: 40px;
    padding-right: 40px;
}
.px-43 {
    padding-left: 43px;
    padding-right: 43px;
}
.px-20 {
    padding-left: 20px;
    padding-right: 20px;
}
.px-10 {
    padding-left: 10px;
    padding-right: 10px;
}
.px-18 {
    padding-left: 18px;
    padding-right: 18px;
}
.px-30 {
    padding-left: 30px;
    padding-right: 30px;
}
.px-6 {
    padding-left: 6px;
    padding-right: 6px;
}
.px-12 {
    padding-left: 12px;
    padding-right: 12px;
}

.px-56 {
    padding-left: 56px;
    padding-right: 56px;
}
.px-67 {
    padding-left: 67px;
    padding-right: 67px;
}

/* padding y */

.py-24 {
    padding-top: 24px;
    padding-bottom: 24px;
}
.py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}
.py-22 {
    padding-top: 22px;
    padding-bottom: 22px;
}
.py-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}
.py-14 {
    padding-top: 14px;
    padding-bottom: 14px;
}
.py-8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

/* total padding */

.p-8 {
    padding: 8px;
}
.p-10 {
    padding: 10px;
}
.p-12 {
    padding: 12px;
}

/* margin left */
.ml-6 {
    margin-left: 6px;
}
.ml-10 {
    margin-left: 10px;
}
.ml-12 {
    margin-left: 12px;
}
.ml-20 {
    margin-left: 20px;
}
.ml-24 {
    margin-left: 24px;
}
.ml-36 {
    margin-left: 36px;
}
.ml-48 {
    margin-left: 48px;
}

.ml-64 {
    margin-left: 64px;
}
.ml-222 {
    margin-left: 222px;
}
.ml-res {
    margin-left: 0px;
}

/* margin right */
.mr-50 {
    margin-right: 50px;
}
.mr-20 {
    margin-right: 20px;
}

/* margin-top */

.mt-res {
    margin-top: 0px;
}
.mt-resmd {
    margin-top: 0px;
}
.mt-53 {
    margin-top: 53px;
}
.mt-25 {
    margin-top: 25px;
}
.mt-43 {
    margin-top: 43px;
}
.mt-46 {
    margin-top: 46px;
}
.mt-88 {
    margin-top: 88px;
}
.mt-97 {
    margin-top: 97px;
}
.mt-13 {
    margin-top: 13px;
}
.mt-9 {
    margin-top: 9px;
}
.mt-4 {
    margin-top: 4px !important;
}
.mt-7 {
    margin-top: 7px;
}
.mt-131 {
    margin-top: 131px;
}
.mt-189 {
    margin-top: 189px;
}
.mt-300 {
    margin-top: 300px;
}
.mt-328 {
    margin-top: 328px;
}
.mt-371 {
    margin-top: 371px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-27 {
    margin-top: 27px;
}
.mt-16 {
    margin-top: 16px;
}
.mt-18 {
    margin-top: 18px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-34 {
    margin-top: 34px;
}
.mt-70 {
    margin-top: 70px;
}
.mt-img {
    margin-top: 0;
}
.mt-10r {
    margin-top: 0px;
}

/* margin bottom */

.mb-20 {
    margin-bottom: 20px;
}
.mb-15 {
    margin-bottom: 15px;
}
.mb-43 {
    margin-bottom: 43px;
}

/* margin whole */
.m-10 {
    margin: 10px;
}

/* shapes margin */
.minus-margin {
    margin-right: -113px;
    margin-top: -132px;
}
.minus-margin2 {
    margin-left: -90px;
    margin-top: 50px;
}
.minus-margin3 {
    margin-left: -123px;
    margin-top: 50px;
}
.minus-margin4 {
    margin-left: -90px;
    margin-top: -73px;
}

/* -margin-left */
.mml-60 {
    margin-left: -60px;
}
.mml-20 {
    margin-left: -20px;
}
.mml-47 {
    margin-left: -47px;
}
.mml-178 {
    margin-left: -178px;
}
.mml-10 {
    margin-left: -10px;
}
.mml-26 {
    margin-left: -26px;
}
/* -margin-top */
.mmt-51 {
    margin-top: -51px;
}
.mmt-10 {
    margin-top: -10px;
}
.mmt-30 {
    margin-top: -29px;
}
.mmt-40 {
    margin-top: -40px;
}

/* -margin-right */
.mmr-30 {
    margin-right: -30px;
}
.mmr-40 {
    margin-right: -40px;
}
.mmr-180 {
    margin-right: -180px;
}
.mr-15 {
    margin-right: 15px;
}
.mr-10 {
    margin-right: 10px;
}
.mmr-10 {
    margin-right: -10px;
}

/* -margin-bottom */
.mmb-29 {
    margin-bottom: -29px;
}
.mmb-15 {
    margin-bottom: -15px;
}
.mmb-50 {
    margin-bottom: -50px;
}

/* card */
.card {
    width: 579px;
    padding-top: 29px;
    padding-bottom: 29px;
    padding-left: 37px;
}
.card2 {
    padding: 57px 60px 56px 56px;
}
.card3 {
    padding: 5px 14px 5px 13px;
}
.card4 {
    padding-top: 16px;
    padding-bottom: 18px;
    padding-left: 19px;
}
.card4:hover {
    box-shadow: 0 3px 12px 0 rgba(170, 120, 234, 0.15);
    background-color: #ffffff;
}
.card4:focus {
    box-shadow: 0 3px 12px 0 rgba(170, 120, 234, 0.15);
    background-color: #ffffff;
}
.item-active {
    box-shadow: 0 3px 12px 0 rgba(170, 120, 234, 0.15);
    background-color: #ffffff;
}

/* shapes */
/*.shape1 {*/
/*    width: 297px;*/
/*    height: 26px;*/
/*    opacity: 0.1;*/
/*    border-radius: 16px;*/
/*    background-image: linear-gradient(to bottom, #8836f2, #d7c9e9);*/
/*}*/

/* box-shadow */
.b-s {
    box-shadow: 0 20px 20px 0 rgba(118, 130, 183, 0.18);
}

/* buttons */
.btn-hero {
    width: 201px;
    height: 50px;
    border-radius: 26px;
}

/* width */
.w-eclipse {
    width: 12%;
}
.w-35 {
    width: 35%;
}
.w-15 {
    width: 15%;
}
.w-20 {
    width: 20%;
}
.w-30 {
    width: 30%;
}
.w-10 {
    width: 10%;
}
.w-75 {
    width: 75% !important;
}

.w-60 {
    width: 60%;
}
.w-65 {
    width: 65%;
}
.w-70 {
    width: 70%;
}
.w-80 {
    width: 80%;
}
.w-40 {
    width: 40%;
}
.w-section2 {
    width: 579px;
}

.w-86px {
    width: 86px;
}
.w-42px {
    width: 42px;
}
.w-32px {
    width: 32px;
}

.w-156px {
    width: 156px;
}
.w-250px {
    width: 250px;
}
.w-52px {
    width: 52px;
}
.w-46px {
    width: 46px;
}
.w-7px {
    width: 7px;
}
.w-396px {
    width: 396.7px;
}
.w-61px {
    width: 61px;
}
.w-129px {
    width: 129px;
}
.w-40px {
    width: 40px;
}
.w-auto {
    width: auto !important;
}
.w-autores {
    width: auto !important;
}

/* height */
.h-260 {
    height: 260px;
}
.h-86px {
    height: 86px;
}
.h-114px {
    height: 114px;
}
.h-42px {
    height: 42px;
}
.h-52px {
    height: 52px;
}
.h-46px {
    height: 46px;
}
.h-7px {
    height: 7px;
}
.h-36px {
    height: 36px;
}
.h-72px {
    height: 72px;
}
.h-64px {
    height: 64px;
}
.h-61px {
    height: 61px;
}
.h-49px {
    height: 49px;
}
.h-293px {
    height: 293px;
}
.h-330px {
    height: 330px;
}
.h-390px {
    height: 390px;
}
.h-129px {
    height: 129px;
}
.h-32px {
    height: 32px;
}
.h-40px {
    height: 40px;
}
.h-30px {
    height: 30px;
}
.h-50 {
    height: 50%;
}
/* overflow */
.overflow-x {
    overflow-x: hidden;
}
.overflow-y {
    overflow-y: auto;
}
/* cursor-pointer */
.cp {
    cursor: pointer;
}

/* image properties */

.object-con {
    object-fit: contain;
}
.object-cov {
    object-fit: cover;
}
.object-cen {
    object-position: center;
}
.object-fill {
    object-fit: fill;
}
.w-50 {
    width: 50% !important;
}
.w-100pc {
    width: 100%
}
.w-img {
    width: 50%;
}
.w-img2 {
    width: auto;
}
.w-img3 {
    width: auto;
}

/* z-index */
.z-0 {
    z-index: 0;
}
.z-10 {
    z-index: 10;
}
.z-20 {
    z-index: 20;
}
.z-30 {
    z-index: 30;
}
.z-40 {
    z-index: 40;
}
.z-50 {
    z-index: 50;
}

/* border-radius */
.br-5 {
    border-radius: 5px;
}
.br-7 {
    border-radius: 7px;
}
.br-10 {
    border-radius: 10px;
}
.br-12 {
    border-radius: 12px;
}
.br-18 {
    border-radius: 18px;
}
.br-24 {
    border-radius: 24.2px;
}

.br-100 {
    border-radius: 100px;
}
.rounded-full {
    border-radius: 9999px;
}

/* border colors */
.b-purple {
    border: solid 4px #bf5dab;
}
.b-purple2 {
    border: solid 2px #bf5dab;
}
.b-sblue {
    border: solid 1px #27debf;
}
.b-gray {
    border: solid 2px #ebebeb;
}
.b-input {
    border: solid 1px #d1e3ff;
}
.b-white {
    border: solid 6px #ffffff;
}
.b-dashed {
    border: dashed 2px #0b1018;
}

/* border-bottom */
.bb-purple {
    border-bottom: solid 1px #bf5dab;
}

/* Dashboard css below */
.wrapper {
    width: 100%;
    min-height: 100vh;
}
main {
    width: 100%;
    height: 100vh;
    background: transparent;
}
.sidebar {
    width: 281px;
    padding: 43px 20px 41px 21px;
    background-color: white;
    height: 100vh;
    z-index: 20;
}
.sidebar-spacer {
    width: 281px;
}
.sidebar,
.header {
    position: fixed;
}
.header-spacer {
    height: 100px;
}
.custom-container {
    width: calc(100vw - 340px);
    margin: 0 auto;
}
.header {
    width: calc(100vw - 281px);
    height: 109px;
    padding-top: 22px;
    padding-bottom: 22px;
    padding-left: 30px;
    background-color: white;
    z-index: 15;
    top: 0;
}
.pr-67 {
    padding-right: 67px;
}

.student-main {
    height: calc(100vh - 250px);
    overflow-y: auto;
    overflow-x: auto;
}
/* -------------------------- */

/* -------------------------- */
/* Common */
.whitespace-nowrap {
    white-space: nowrap;
}
.w-full {
    width: 100%;
}
.opacity-0 {
    opacity: 0;
}
.resize-none {
    resize: none;
}
.filter10 {
    filter: blur(10px);
}

.filter50 {
    filter: blur(50px);
}
.text-center {
    text-align: center;
}

.h-full {
    height: 100%;
}
.inset-0 {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.top-0 {
    top: 0;
}
.bottom-0 {
    bottom: 0;
}
.left-0 {
    left: 0;
}
.right-0 {
    right: 0;
}
.m-auto {
    margin: auto;
}
.object-cover {
    object-fit: cover;
}
.object-center {
    object-position: center;
}
.flex-shrink-0 {
    flex-shrink: 0;
}
.cursor-pointer {
    cursor: pointer;
}
.focus-outline-none:focus {
    outline: none;
}
.shadow-1 {
    box-shadow: 0 5px 10px 0 rgba(170, 120, 234, 0.1);
}
.shadow-2 {
    box-shadow: 0 2.2px 3.8px 0 rgba(0, 0, 0, 0);
}
.shadow-3 {
    box-shadow: 0 12.1px 24.2px 0 rgba(0, 0, 0, 0.15);
}
.shadow-4 {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
}

.rotate:hover {
    transition: 1s;
    transform: rotate(-45deg);
}
.rotate-90 {
    transform: rotate(-90deg);
}

.rotatebtn {
    transition: 1s;
}

.rotatebtn:hover .tobe {
    transition: 1s;
    transform: rotate(-45deg);
}

/* -------------------------- */
/* Question alt css below */
.card-with-pic {
    width: 100%;
    padding: 9px 8px 11px 9px;
    border-radius: 20px;
    background-color: #ffffff;
}
.z-20 {
    z-index: 20;
}
.n-props {
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}
.clr-grey {
    color: #272d3b;
}
.card-with-pic h2 {
    margin-bottom: 12px;
}
.card-with-pic .img {
    border-radius: 14px;
    box-shadow: 0 3px 20px 0 rgba(170, 120, 234, 0.2);
}
.card-with-pic .user {
    width: 40px;
    height: 40px;
    border-radius: 50px;
}
.mx-25 {
    margin: 0 -50px;
}
.p-25 {
    padding: 25px 30px;
}
.px-15px {
    padding: 0 15px;
}
.question-alt .scroller {
    height: calc(100vh - 260px);
    overflow-y: auto;
}

/* Custom scrollbar  */
/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 50px;
}

/* Track */
::-webkit-scrollbar-track {
    border: 2px solid #bbb;
    border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #80848a;
    border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #6b7280;
}

.b-tab {
    padding: 4.6px 4.6px 4.6px 4.3px;
    border-radius: 12px;
    box-shadow: 0 5px 10px 0 rgba(170, 120, 234, 0.2);
    background-color: #ffffff;
}
.b-tab button {
    padding: 14px 25px;
    border-radius: 11px;
    box-shadow: 0 2.2px 3.8px 0 rgba(170, 120, 234, 0.13);
}
.bg-purple {
    background-color: #bf5dab;
}
.mr-8 {
    margin-right: 8px;
}
.nav-tab .new-btn {
    border-radius: 12px;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    font-size: 14.5px;
    width: 171.4px;
    height: 49.3px;
}
.nav-tab .new-btn:hover {
    background: #bf5dab;
    color: #fff;
}
.clr-purple {
    color: #bf5dab;
}
.arrow-left-btn,
.arrow-right-btn {
    width: 40px;
    height: 40px;
    padding: 10.5px 10px 12px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-radius: 50px;
}
.arrow-left-btn button,
.arrow-right-btn button {
    border: none !important;
    background: transparent;
}
.arrow-left-btn {
    margin-left: -15px !important;
}
.arrow-right-btn {
    margin-right: -15px !important;
}
.mb-50 {
    margin-bottom: 50px;
}
.card-with-pic .b-overlay {
    opacity: 0.15;
    border-radius: 20px;
    background-color: #000000;
}
.class-detail {
    margin-top: 35px;
}
.mb-30 {
    margin-bottom: 30px;
}
.v-scroll {
    padding-left: 36px;
    padding-right: 15px;
    height: calc(100vh - 109px - 67vh);
    overflow-y: auto;
}
.v-scroll2 {
    padding-left: 36px;
    padding-right: 15px;
    height: calc(100vh - 109px - 44vh);
    overflow-y: auto;
}
.v-scroll3 {
    padding-left: 36px;
    padding-right: 15px;
    height: calc(100vh - 109px);
    overflow-y: auto;
}
.overflow-y::-webkit-scrollbar,
.student-main::-webkit-scrollbar,
.scroller::-webkit-scrollbar,
.v-scroll::-webkit-scrollbar,
.v-scroll2::-webkit-scrollbar,
.v-scroll3::-webkit-scrollbar {
    width: 0px;
}
.overflow-y:hover::-webkit-scrollbar,
.student-main:hover::-webkit-scrollbar,
.scroller:hover::-webkit-scrollbar,
.v-scroll:hover::-webkit-scrollbar,
.v-scroll2:hover::-webkit-scrollbar,
.v-scroll3:hover::-webkit-scrollbar {
    width: 6px;
}
.ml-40 {
    margin-left: 40px;
}
.tag-btn {
    padding: 3.6px 14.5px 4.8px 16.9px;
    border-radius: 3.6px;
    background-color: rgb(0 106 255 / 10%);
    margin-bottom: 15px;
    color: #006aff;
}
.fs10 {
    font-size: 10px;
}
a {
    text-decoration: none !important;
    color: #808291
}
.mt-26 {
    margin-top: 26px;
}
.pb-21 {
    padding-bottom: 21px;
}

.site-nav a:hover {
    color: #006aff !important;
}
.mobile-header {
    display: none;
}
.overflow-x-auto {
    overflow-x: auto;
}
.mobile-carousel {
    display: none;
}
.d-mobile{
    display: none;
}
.d-desktop{
    display: flex;
}

/* responsive */

@media (min-width: 320px) and (max-width: 767px) {
    .w-f-i{
        width: 100% !important;
    }
    .d-mobile{
        display: flex;
    }
    .d-desktop{
        display: none;
    }
    button.border-0.br-12.purple-bg.f-w-b.text-white.px-30.py-14 {
        padding: 5px 25px;
    }
    button.border-0.h-36px.br-10.bg-white.shadow-2.ml-6.px-10.d-flex.flex-column.justify-content-center {
        display: none !important;
    }
    .mobile-sidebar-shrink {
        position: fixed;
        right: 0;
        top: 144px;
    }
    .mobile-sidebar-shrink.w-10,.mobile-sidebar-shrink.w-30{
        width: auto !important;
    }
    .mobile-sidebar-shrink .ml-10.w-full {
        background: #fff;
        background: #fff;
        padding: 12px;
        width: 315px !important;
    }
    .custom-container {
        width: 100%;
    }
    .arrow-left-btn {
        margin-left: 0px !important;
    }
    .arrow-right-btn {
        margin-right: 0px !important;
    }
    .mobile-carousel {
        display: block;
    }
    .desktop-carousel {
        display: none;
    }
    .py-22 {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .m-mb-15px {
        margin-bottom: 15px;
    }
    .px-30 {
        padding-left: 5px;
        padding-right: 5px;
    }
    .w-70 {
        width: 100%;
    }
    .pl-22 {
        padding-left: 0px;
    }
    .mt-43 {
        margin-top: 10px;
    }
    .mb-43 {
        margin-bottom: 10px;
    }
    .px-67 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .pl-74 {
        padding-left: 10px;
    }
    .mt-res {
        margin-top: 20px;
    }
    .mt-resmd {
        margin-top: 20px;
    }
    /* line heights */

    .l72 {
        line-height: 20px;
    }
    .l30 {
        line-height: 20px;
    }

    /* fonts */
    .fs20 {
        font-size: 14px;
    }
    .fs18 {
        font-size: 12px;
    }
    .fs60 {
        font-size: 32px;
    }
    .fs24 {
        font-size: 14px;
    }
    .fs15 {
        font-size: 12px;
    }
    .fs49 {
        font-size: 25px;
    }
    .fs50 {
        font-size: 26px;
    }
    .fs27 {
        font-size: 18px;
    }
    .fs23 {
        font-size: 15px;
    }
    .fs35 {
        font-size: 20px;
    }

    /* cards */
    .card {
        width: 340px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
    }
    .card2 {
        padding: 30px 32px 30px 30px;
    }

    /* minus margins */

    .mml-60 {
        margin-left: 0px;
    }
    .mml-20 {
        margin-left: 0px;
    }
    .mmr-180 {
        margin-right: 0px;
    }
    .mmr-30 {
        margin-right: 0px;
    }
    .mmr-40 {
        margin-right: -5px;
    }

    /* margins */
    .mt-10 {
        margin-top: 5px;
    }
    .mt-10r {
        margin-top: 10px;
    }
    .mt-70 {
        margin-top: 30px;
    }
    .mt-88 {
        margin-top: 40px;
    }
    .ml-222 {
        margin-left: 0px;
    }
    .ml-20 {
        margin-left: 10px;
    }
    .ml-64 {
        margin-left: 0px;
    }
    .mt-30 {
        margin-top: 15px;
    }
    .mt-20 {
        margin-top: 15px;
    }
    .mt-328 {
        margin-top: 100px;
    }
    .mt-371 {
        margin-top: 100px;
    }
    .mt-189 {
        margin-top: 100px;
    }
    .mt-131 {
        margin-top: 70px;
    }
    .mt-300 {
        margin-top: 100px;
    }
    .mt-img {
        margin-top: 40px;
    }
    .minus-margin2 {
        margin-left: -20px;
        margin-top: 30px;
    }
    .ml-res {
        margin-left: 40px;
    }

    .ml-36 {
        margin-left: 0px;
    }

    /* paddings */
    .pt-215 {
        padding-top: 100px;
    }
    .px-40 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .px-56 {
        padding-left: 40px;
        padding-right: 40px;
    }

    /* widths */

    .w-section2 {
        width: 340px;
    }
    .w-75 {
        width: 100% !important;
    }
    .w-30 {
        width: 100%;
    }
    .w-10 {
        width: 100%;
    }
    .w-70 {
        width: 95%;
    }
    .w-396px {
        width: 340px;
    }
    .w-35 {
        width: 100%;
    }
    .w-auto {
        width: 100% !important;
    }
    .w-60 {
        width: 100%;
    }
    .w-autores {
        width: 90% !important;
    }

    /* heights */

    .flipper {
        position: relative;
        height: 80px !important;
    }
    .h-72px {
        height: 40px;
    }

    .mobile-header {
        display: flex;
    }
    .sidebar {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background: #fff;
        z-index: 30;
    }
    .sidebar-spacer,
    .header-spacer,
    .header,
    .desktop {
        display: none;
    }
    .transform-left {
        transform: translateX(-300px);
    }
    .transform-0 {
        animation: transformX 0.5s;
        transform: translateX(0);
    }
    .mobile-header {
        padding: 23px 12px 21px 14px;
    }
    @keyframes transformX {
        from {
            transform: translateX(-300px);
        }
        to {
            transform: translateX(0);
        }
    }
    .mobile-header .h-46px {
        height: 40px;
    }

    .mobile-header .w-46px {
        width: 40px;
    }
    .mobile-header .ml-48 {
        margin-left: 12px;
    }
    .mobile-header .h-64px {
        height: 40px;
    }

    .mobile-header .w-40 {
        width: 40px;
    }

    .w-50 {
        width: 100% !important;
    }
    .w-img {
        width: 75%;
    }
    .w-img2 {
        width: 95%;
    }
    .w-img3 {
        width: 30%;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .w-f-i{
        width: 100% !important;
    }
    .d-mobile{
        display: flex;
    }
    .d-desktop{
        display: none;
    }
    button.border-0.br-12.purple-bg.f-w-b.text-white.px-30.py-14 {
        padding: 5px 25px;
    }
    button.border-0.h-36px.br-10.bg-white.shadow-2.ml-6.px-10.d-flex.flex-column.justify-content-center {
        display: none !important;
    }
    .mobile-sidebar-shrink {
        position: fixed;
        right: 0;
        top: 144px;
    }
    .mobile-sidebar-shrink .ml-10.w-full {
        background: #fff;
        background: #fff;
        padding: 12px;
        width: 315px !important;
    }
    .mobile-sidebar-shrink.w-10,.mobile-sidebar-shrink.w-30{
        width: auto !important;
    }
    .custom-container {
        width: 100%;
    }
    .arrow-left-btn {
        margin-left: 0px !important;
    }
    .arrow-right-btn {
        margin-right: 0px !important;
    }
    .mobile-carousel {
        display: block;
    }
    .desktop-carousel {
        display: none;
    }
    .m-mb-15px {
        margin-bottom: 15px;
    }
    .px-30 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .w-70 {
        width: 100%;
    }
    /* line heights */
    .pl-22 {
        padding-left: 0px;
    }
    .px-67 {
        padding-left: 14px;
        padding-right: 14px;
    }
    .pl-74 {
        padding-left: 10px;
    }
    .mt-res {
        margin-top: 20px;
    }
    .w-img3 {
        width: 30%;
    }
    .l72 {
        line-height: 40px;
    }
    .l30 {
        line-height: 20px;
    }

    /* fonts */
    .fs35 {
        font-size: 26px;
    }
    .fs70 {
        font-size: 40px;
    }
    .fs20 {
        font-size: 16px;
    }
    .fs18 {
        font-size: 14px;
    }
    .fs60 {
        font-size: 34px;
    }
    .fs24 {
        font-size: 16px;
    }
    .fs15 {
        font-size: 12px;
    }
    .fs49 {
        font-size: 27px;
    }
    .fs50 {
        font-size: 28px;
    }
    .fs27 {
        font-size: 20px;
    }
    .fs23 {
        font-size: 17px;
    }

    /* cards */
    .card {
        width: 380px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
    }
    .card2 {
        padding: 30px 32px 30px 30px;
    }

    /* minus margins */

    .mml-60 {
        margin-left: -30px;
    }
    .mml-20 {
        margin-left: -16px;
    }
    .mmr-180 {
        margin-right: 0px;
    }
    .mmr-30 {
        margin-right: -20px;
    }
    .mmr-40 {
        margin-right: -5px;
    }

    /* margins */
    .mt-10 {
        margin-top: 5px;
    }
    .mt-10r {
        margin-top: 10px;
    }
    .mt-70 {
        margin-top: 30px;
    }
    .mt-88 {
        margin-top: 40px;
    }
    .ml-222 {
        margin-left: 40px;
    }
    .ml-20 {
        margin-left: 10px;
    }
    .ml-64 {
        margin-left: 0px;
    }
    .mt-30 {
        margin-top: 15px;
    }
    .mt-20 {
        margin-top: 15px;
    }
    .mt-328 {
        margin-top: 100px;
    }
    .mt-371 {
        margin-top: 100px;
    }
    .mt-189 {
        margin-top: 100px;
    }
    .mt-131 {
        margin-top: 70px;
    }
    .mt-300 {
        margin-top: 100px;
    }
    .mt-img {
        margin-top: 40px;
    }
    .minus-margin2 {
        margin-left: -20px;
        margin-top: 30px;
    }
    .ml-res {
        margin-left: 40px;
    }

    .ml-36 {
        margin-left: 15px;
    }

    /* paddings */
    .pt-215 {
        padding-top: 100px;
    }
    .px-40 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .px-56 {
        padding-left: 40px;
        padding-right: 40px;
    }

    /* widths */

    .w-section2 {
        width: 340px;
    }
    .w-75 {
        width: 100% !important;
    }
    .w-70 {
        width: 95%;
    }
    .w-396px {
        width: 340px;
    }
    .w-35 {
        width: 100%;
    }
    .w-auto {
        width: 100% !important;
    }
    .w-60 {
        width: 100%;
    }
    .w-autores {
        width: 50% !important;
    }
    .w-img2 {
        width: 90%;
    }
    .w-30 {
        width: 100%;
    }
    /* heights */

    .flipper {
        position: relative;
        height: 80px !important;
    }
    .h-72px {
        height: 40px;
    }

    .w-img {
        width: 50%;
    }
    .sidebar {
        position: absolute;
    }
    .card4 {
        padding-top: 10px;
        padding-bottom: 12px;
        padding-left: 13px;
    }
    .mt-26 {
        margin-top: 16px;
    }

    .sidebar {
        transition: 0.5s ease-in-out;
        width: 210px;
        padding: 23px 12px 21px 14px;
        background-color: white;
        height: 100vh;
        z-index: 20;
    }
    .mt-34 {
        margin-top: 24px;
    }
    .mt-27 {
        margin-top: 20px;
    }
    .fs22 {
        font-size: 14px;
    }
    .header {
        width: calc(100vw - 281px);
        height: 130px;
        padding-top: 22px;
        padding-bottom: 22px;
        padding-left: 20px;
        padding-right: 10px;
        background-color: white;
        z-index: 15;
        top: 0;
    }
    .pr-67 {
        padding-right: 10px;
    }
    .w-40 {
        width: 60%;
    }
    .w-35 {
        width: 40%;
    }
    .h-64px {
        height: 50px;
    }
    .mmr-180 {
        margin-right: -25px;
    }
}

@media (min-width: 1024px) and (max-width: 1365px) {
    .px-30 {
        padding-left: 15px;
        padding-right: 15px;
    }
    .pl-22 {
        padding-left: 0px;
    }
    .w-60 {
        width: 100%;
    }
    .w-70 {
        width: 100%;
    }
    .w-10 {
        width: 100%;
    }
    .w-30 {
        width: 100%;
    }
    .mt-res {
        margin-top: 30px;
    }
    .px-67 {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (min-width: 1024px) and (max-width: 1600px) {
    /* line heights */

    .w-img3 {
        width: 30%;
    }
    .pl-74 {
        padding-left: 40px;
    }

    /* fonts */
    .fs70 {
        font-size: 60px;
    }
    .fs20 {
        font-size: 16px;
    }
    .fs18 {
        font-size: 16px;
    }
    .fs60 {
        font-size: 38px;
    }
    .fs24 {
        font-size: 23px;
    }
    .fs15 {
        font-size: 13px;
    }
    .fs49 {
        font-size: 31px;
    }
    .fs50 {
        font-size: 32px;
    }
    .fs27 {
        font-size: 24px;
    }
    .fs23 {
        font-size: 20px;
    }

    /* cards */
    .card {
        width: 480px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
    }
    .card2 {
        padding: 30px 32px 30px 30px;
    }

    /* minus margins */

    /* margins */
    .mt-10 {
        margin-top: 10px;
    }

    .mt-70 {
        margin-top: 60px;
    }
    .mt-88 {
        margin-top: 70px;
    }
    .ml-222 {
        margin-left: 100px;
    }
    .ml-20 {
        margin-left: 20px;
    }
    .ml-64 {
        margin-left: 64px;
    }
    .mt-30 {
        margin-top: 24px;
    }
    .mt-20 {
        margin-top: 18px;
    }

    /* paddings */

    .px-40 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .px-56 {
        padding-left: 40px;
        padding-right: 40px;
    }

    /* widths */

    .w-section2 {
        width: 480px;
    }

    .w-35 {
        width: 100%;
    }
    .w-auto {
        width: 100% !important;
    }

    .w-autores {
        width: 50% !important;
    }
    .w-img2 {
        width: 90%;
    }
    /* heights */

    .flipper {
        position: relative;
        height: 80px !important;
    }

    .w-img {
        width: 50%;
    }

    .card4 {
        padding-top: 10px;
        padding-bottom: 12px;
        padding-left: 13px;
    }
    .mt-26 {
        margin-top: 16px;
    }

    .sidebar {
        width: 281px;
        padding: 23px 12px 21px 14px;
        background-color: white;
        height: 100vh;
        z-index: 20;
    }
    .mt-34 {
        margin-top: 24px;
    }
    .mt-27 {
        margin-top: 20px;
    }
    .fs22 {
        font-size: 14px;
    }
    .h-32px {
        height: 20px;
    }
    .w-32px {
        width: 20px;
    }
    .w-30px {
        width: 30px;
    }
    .h-64px {
        height: 50px;
    }
    .fs20 {
        font-size: 16px;
    }
    .h-30px {
        height: 20px;
    }
    .w-30px {
        height: 20px;
    }
    .fs15 {
        font-size: 13px;
    }
    .ml-48 {
        margin-left: 36px;
    }
    .header {
        width: calc(100vw - 281px);
        height: 109px;
        padding-top: 22px;
        padding-bottom: 22px;
        padding-left: 20px;
        padding-right: 10px;
        background-color: white;
        z-index: 15;
        top: 0;
    }
    .pr-67 {
        padding-right: 10px;
    }
    .w-40 {
        width: 60%;
    }
    .w-35 {
        width: 40%;
    }
    .mmr-180 {
        margin-right: 0px;
    }
}
.flipper {
    position: relative;
    height: 110px;
}

.flipper {
    perspective: 600px;
    -webkit-perspective: 600px;
    -moz-perspective: 600px;

    -webkit-transform-origin: 100% center;
    -moz-transform-origin: 100% center;
    -ms-transform-origin: 100% center;
    transform-origin: 100% center;
}

.front-card,
.back-card {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;

    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;

    -o-transition: transform 0.6s ease-in-out;
    -moz-transition: transform 0.6s ease-in-out;
    -webkit-transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;

    position: absolute;
    top: 0;
    left: 0;
}

.front-card {
    z-index: 2;
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    transform: rotateX(0deg);
}
.flipper.flip .front-card {
    -webkit-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.back-card {
    -webkit-transform: rotateX(-180deg);
    -moz-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
}

.flipper.flip .back-card {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    transform: rotateX(0deg);
}


.nicer {
    z-index: 1000000000000000000000000000;
}

.centerSpinner {
    /*margin: 0;*/
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

 /*For hiding main screen message on
 big screens and showing on small screens*/
@media only screen and (min-width: 760px) {
    .d-md-hidden {
        display: none;
    }
}